<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">{{ $t("My order") }}</p>
      </div>

      <div class="input">
        <div class="input_content">
          <p class="input_text">{{ $t("Order Status") }}：</p>
          <el-select
            v-model="requestParams.status"
            clearable
            class="input_select"
            :placeholder="$t('Please select order status')"
          >
            <el-option
              v-for="item in status_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="input_content">
          <p class="input_text">{{ $t("Time Range") }}：</p>
          <el-select
            v-model="requestParams.cycle"
            clearable
            class="input_select"
            :placeholder="$t('Please select a time period')"
          >
            <el-option
              v-for="item in cycle_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="activity">
        <span>Year End Promotion Key: </span>
        <el-input v-model="activityKey" class="activity-key" />
        <el-button @click="handleSubmitActivityKey" class="activity-submit">Submit key</el-button>
      </div>

      <div class="button_style">
        <el-button
          style="background: #218da0; color: white; font-size: 16px"
          icon="el-icon-download"
          @click="exportOrders()"
        >
          {{ $t("Export") }}
        </el-button>
        <p style="margin-left: 10px; font-size: 16px">
          {{ $t("Total") }}:￥ {{ tableDataTotal }}
        </p>
      </div>

      <div class="table_style">
        <el-table
          ref="multipleTable"
          header-align="center"
          border
          class="main-table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; font-size: 18px; color: #515a6e"
          :header-cell-style="{ color: 'black' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column
            :label="$t('Order Status')"
            prop="operation"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tag
                v-if="row.order_status == 0"
                type="danger"
                style="font-size: 16px"
              >
                {{ $t("To be Transferred") }}</el-tag
              >
              <el-tag v-if="row.order_status == 1" style="font-size: 16px">{{
                $t("Transferred")
              }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column
            prop="order_sn"
            :label="$t('Order Number')"
            align="center"
          />

          <el-table-column
            prop="order_amount"
            :label="$t('Amount Paid')"
            align="center"
          />

          <el-table-column
            :label="$t('Order Submit Time')"
            prop="operation"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.created_at | formatDateStr }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('Operation')"
            prop="operation"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button @click="handleViewDetails(row)" style="font-size: 16px"
                >{{ $t("Order Details") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.page"
            :page-size="pages.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="pages.total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from "@/common/mixins/common.js";
import { GetOrderList } from "@/api/order/order";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      tableData: [],
      tableDataTotal: 0,
      //分页参数
      pages: {
        page: 1,
        pageSize: 10,
        total: 10,
      },
      //currentPage4: 4,
      requestParams: {
        status: "", //查询订单状态
        cycle: "",
        timeBegin: "", //开始时间
        timeEnd: "", //结束时间
      },
      status_list: [
        {
          value: 1,
          label: "Transferred", //已传输
        },
        {
          value: 0,
          label: "To be Transferred", //待传输
        },
      ],

      cycle_list: [
        {
          value: 1,
          label: "ALL", //全部
        },
        {
          value: 2,
          label: "In the last month", //最近一个月
        },
        {
          value: 3,
          label: "In the last three months", //最近三个月
        },
        {
          value: 4,
          label: "In the last six months", //最近六个月
        },
        {
          value: 5,
          label: "In the last year", //在过去的一年
        },
      ],
      selExportOrder: [],
      activityKey:''
    };
  },
  watch: {
    //订单数据修改
    "requestParams.status"() {
      this.getOrderList();
    },
    "requestParams.cycle"() {
      this.selectOrderStatus();
    },
    tableData() {
      this.getTableListTotal();
    },
  },
  created() {
    this.getOrderList();
  },
  methods: {
    //导出订单excel
    exportOrders() {
      let ids = [];
      for (let i = 0; i < this.selExportOrder.length; i++) {
        ids.push(this.selExportOrder[i].id);
      }
      if (ids.length < 1) {
        this.$message.error("请选择需要导出的订单");
      } else {
        let lgName = "";
        if (this.$i18n.locale == "zh-CN") {
          lgName = "cn";
        } else {
          lgName = "en";
        }
        let postData = {
          lg: lgName,
          order_ids: ids,
        };

        this.$axios
          .post("/w1/order/createOrderDownKey", postData)
          .then((res) => {
            let resData = res.data.data;
            window.location.href =
              this.$root.DownUrl + "/c1/down/" + resData.to_key;
          });
      }
    },
    //行
    handleSelectionChange(val) {
      this.selExportOrder = val;
    },
    //选择订单状态
    selectOrderStatus() {
      let keyVal = this.requestParams.cycle;
      let nowTime = Date.parse(new Date()) / 1000;
      switch (keyVal) {
        case 1:
          this.requestParams.timeBegin = "";
          this.requestParams.timeEnd = "";
          break;
        case 2:
          this.requestParams.timeBegin = nowTime - 3600 * 24 * 30 * 1;
          this.requestParams.timeEnd = nowTime;
          break;
        case 3:
          this.requestParams.timeBegin = nowTime - 3600 * 24 * 30 * 3;
          this.requestParams.timeEnd = nowTime;
          break;
        case 4:
          this.requestParams.timeBegin = nowTime - 3600 * 24 * 30 * 6;
          this.requestParams.timeEnd = nowTime;
          break;
        case 5:
          this.requestParams.timeBegin = nowTime - 3600 * 24 * 30 * 24;
          this.requestParams.timeEnd = nowTime;
          break;
        default:
          this.requestParams.timeBegin = "";
          this.requestParams.timeEnd = "";
      }
      this.getOrderList();
    },
    //计算当前订单页面的价格总数
    getTableListTotal() {
      let total = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        total += this.tableData[i].order_amount;
      }
      this.tableDataTotal = total;
    },
    //获取新订货
    getOrderList() {
      let getQuery = {
        status: this.requestParams.status,
        timeBegin: this.requestParams.timeBegin,
        timeEnd: this.requestParams.timeEnd,
        page: this.pages.page,
        page_size: this.pages.pageSize,
      };
      GetOrderList({
        params: getQuery,
      }).then((res) => {
        let resData = res.data.data;
        this.tableData = resData.data;
        this.pages.page = resData.page;
        this.pages.pageSize = resData.page_size;
        this.pages.total = resData.total;
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pages.page = val;
      this.getOrderList();
    },
    handleViewDetails(row) {
      this.$router.push({
        path: "/web/orderCenter/orderDetails",
        query: {
          id: row.id,
        },
      });
    },
    handleSubmitActivityKey(){
      this.$axios.post("/w1/order/activity",{
        activity_key:this.activityKey
      }).then(() => {
        this.$message.success("Order Success")
        this.getOrderList()
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1120px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  //display: flex;
  align-items: flex-start;
  justify-content: center;

  &_inner {
    max-height: 1000px;
    padding:30px;

    &_title {
      width: 1100px;
      height: 68px;
      padding-top: 20px;
    }
  }
}

.table_style {
  width: 1100px;
  margin-top: 30px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;

  &_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 0 -12px;
    width: 400px;
  }

  &_text {
    width: 120px;
    flex-shrink: 0;
    text-align: right;
  }

  &_select {
    width: 270px;
    flex-shrink: 0;
  }
}

.button_style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.activity{
  padding: 10px;
  .activity-key{
    margin: 0 10px;
    width: 400px;
  }
  .activity-submit{

  }
}

</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}

</style>
